/* src/styles/global.css */


@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;700&display=swap');

:root {
    --border-width-thin-aug: 5px;
    --border-color-aug:  #2c2c2c; 
    --border-color-aug-overlay: #2c2c2c; 

    /* --inlay-color-aug: red; */
    --bg-color-dark: hsl(204, 100%, 1%);
    --bg-color-darker: #000000;
    --text-color-bright: #ffffff;
    --text-color-dim: #d9d9d9;
    --text-color-dark: #2c2c2c;
    
    --color-transparent: transparent;
    --color-semi-transparent: rgba(255, 255, 255, 0.1);
    --color-glow: rgba(0, 255, 255, 0.1);
    --color-scanline: rgba(0, 255, 255, 0.15);

    --font-family-main: 'Orbitron', sans-serif;
    --letter-spacing: 0.12em;
}

body,
html {
    margin: 0;
    padding: 0;
    height: 100%;
    background-color: var(--bg-color-darker);
    color: var(--text-color-dim);
    font-family: var(--font-family-main);
    letter-spacing: var(--letter-spacing);
    min-width: 320px;
}

h1, h2, h3, h4, h5, h6 {
    letter-spacing: calc(var(--letter-spacing) * .4 );
    color: var(--text-color-dim);
    font-family: var(--font-family-main);
}


.common-aug-mixin {
    --hsl1: hsl(0, 5%, 5%);
    --hsl2: hsl(315deg, 10%, 6%);
    --hsl3: hsl(270deg, 10%, 5%);
    --hsl4: hsl(225deg, 10%, 4%);
    --hsl5: hsl(180deg, 10%, 7%);
    --hsl6: hsl(135deg, 10%, 2%);
    --hsl7: hsl(90deg, 10%, 1%);
    --hsl8: hsl(45deg, 10%, 5%);

    --aug-border-all: var(--border-width-thin-aug);
    --aug-border-bg: var(--border-color-aug);
    --aug-inlay-bg: var(--inlay-color-aug) !important;
    --aug-border-opacity: 0.3;
    --aug-inlay-opacity: 1.0;

    background: 
    radial-gradient(at top left, var(--hsl1), transparent 40%), 
    radial-gradient(at top center, var(--hsl2), transparent 40%), 
    radial-gradient(at top right, var(--hsl3), transparent 40%), 
    radial-gradient(at center right, var(--hsl4), transparent 40%), 
    radial-gradient(at bottom right, var(--hsl5), transparent 40%), 
    radial-gradient(at bottom center, var(--hsl6), transparent 40%), 
    radial-gradient(at bottom left, var(--hsl7), transparent 40%), 
    radial-gradient(at center left, var(--hsl8), transparent 40%), 
    black;
}  

.common-aug-overlay-mixin {
    --hsl1: hsl(0, 0%, 4%);
    --hsl2: hsl(0, 0%, 1%);
    --hsl3: hsl(0, 0%, 0%);
    --hsl4: hsl(240, 5%, 8%);
    --hsl5: hsl(180, 8%, 5%);
    --hsl6: hsl(135, 9%, 9%);
    --hsl7: hsl(90, 7%, 6%);
    --hsl8: hsl(60, 11%, 4%);

    --aug-border-all: var(--border-width-thin-aug);
    --aug-border-bg: var(--border-color-aug-overlay);
    --aug-inlay-bg: var(--inlay-color-aug) !important;
    --aug-border-opacity: 0.3;
    --aug-inlay-opacity: 1.0;

    background: 
    radial-gradient(at top left, var(--hsl1), transparent 40%), 
    radial-gradient(at top center, var(--hsl2), transparent 40%), 
    radial-gradient(at top right, var(--hsl3), transparent 40%), 
    radial-gradient(at center right, var(--hsl4), transparent 40%), 
    radial-gradient(at bottom right, var(--hsl5), transparent 40%), 
    radial-gradient(at bottom center, var(--hsl6), transparent 40%), 
    radial-gradient(at bottom left, var(--hsl7), transparent 40%), 
    radial-gradient(at center left, var(--hsl8), transparent 40%), 
    black;
}  


.common-aug-tl-mixin,
.common-aug-tr-mixin,
.common-aug-bl-mixin,
.common-aug-br-mixin {
    --aug-tr1: 25px;
    --aug-br1: 25px;
    --aug-b-extend1: 30%;
    --aug-t-extend1: 22%;
}

.common-aug-tr-mixin,
.common-aug-br-mixin {
    --aug-tl1: 25px;
    --aug-bl1: 25px;
}


.common-aug-shallow-mixin {
    --aug-tr1: 25px;
    --aug-tl1: 25px;
    --aug-br1: 25px;
    --aug-bl1: 25px;
    --aug-b-extend1: 20%;
    --aug-t-extend1: 22%;
}

.andr3w-logo {
    width: 100%;
    height: 100%;
    object-fit: contain;
    filter: drop-shadow(0 0 5px var(--text-color-dim));
}

.andr3w-face-logo {
    width: 100%;
    height: 100%;
    object-fit: contain;
    filter: drop-shadow(0 0 7px var(--text-color-dim));
}
/* 
.span.p-dropdown-label.p-inputtext.p-placeholder {
    font-family: var(--font-family-main) !important;
    letter-spacing: var(--letter-spacing);
} */

.p-button {
    background-color: var(--text-color-dim) !important;
    color: var(--bg-color-darker) !important;
    border: none !important;
    transition: all 0.3s ease !important;
    font-family: var(--font-family-main) !important;
    letter-spacing: var(--letter-spacing);
}

.p-button:hover {
    background-color: var(--text-color-dim) !important;
    transform: translateY(-2px);
    box-shadow: 0 4px 6px var(--color-glow);
}

.p-toolbar {
    background-color: var(--bg-color-darker) !important;
    font-family: var(--font-family-main) !important;
    letter-spacing: var(--letter-spacing);
}

.p-dialog {
    font-family: var(--font-family-main) !important;
    letter-spacing: var(--letter-spacing);
}

.p-dialog-content {
    background-color: var(--bg-color-darker) !important;
}

.p-dialog .p-dialog-header  {
    background-color: var(--bg-color-darker) !important;
    color: var(--text-color-dim) !important;
}

.p-datatable-header  {
    background-color: var(--bg-color-darker) !important;
    color: var(--text-color-dim) !important;
}

.p-datatable .p-datatable-tbody > tr > td   {
    background-color: var(--bg-color-darker) !important;
    color: var(--text-color-dim) !important;
}

.p-datatable .p-datatable-thead > tr > th   {
    background-color: var(--bg-color-darker) !important;
    color: var(--text-color-dim) !important;
}

.p-paginator  {
    background-color: var(--bg-color-darker) !important;
    color: var(--text-color-dim) !important;
}

.p-inputtext {
    background-color: var(--bg-color-darker) !important;
    color: var(--text-color-dim) !important;
    border: 1px solid var(--border-color-aug) !important;
    font-family: var(--font-family-main) !important;
    letter-spacing: var(--letter-spacing);
}

.p-dropdown {
    background-color: var(--bg-color-darker) !important;
    color: var(--text-color-dim) !important;
    border: 1px solid var(--border-color-aug) !important;
    font-family: var(--font-family-main) !important;
    letter-spacing: var(--letter-spacing);
}

.p-dropdown-panel {
    background-color: var(--bg-color-darker) !important;
    color: var(--text-color-dim) !important;
    border: 1px solid var(--border-color-aug) !important;
    font-family: var(--font-family-main) !important;
    letter-spacing: var(--letter-spacing);
}

.p-dropdown-label.p-placeholder {
    font-family: var(--font-family-main) !important;
    letter-spacing: var(--letter-spacing);
}

[data-augmented-ui]::after {
    box-shadow: 0 0 10px var(--text-color-bright);
}

.grid > * {
    background-color: var(--color-transparent) !important;
}

.accordion.hover,
.pricing-card:hover,
.use-case-card:hover,
.p-button:hover {
    box-shadow: 0 0 15px var(--bg-color-darker);
    transform: translateY(-3px);
    transition: all 0.3s ease;
}

.p-inputtext:focus {
    box-shadow: 0 0 10px var(--text-color-bright);
    outline: none;
}

.space-console::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* background: linear-gradient(var(--color-transparent) 50%, var(--color-scanline) 50%); */
    background: linear-gradient(var(--color-transparent) 90%, var(--color-scanline) 50%);
    background-size: 100% 4px;
    z-index: 1000;
    pointer-events: none;
    animation: scanline 30s linear infinite;
    /* animation: scanline 10s linear infinite; */
}

@keyframes scanline {
    0% { background-position: 0 0; }
    100% { background-position: 0 100%; }
}

.space-console {
    background-color: var(--bg-color-dark);
    color: var(--text-color-dim);
    font-family: var(--font-family-main) !important;
}



.p-menubar {
    background-color: var(--bg-color-darker);
    border: none;
}

.p-menubar .p-menuitem-link {
    color: var(--text-color-dim);
}

.p-menubar .p-menuitem-link:hover {
    background-color: var(--color-semi-transparent);
}


.use-case-card {
    background-color: var(--color-semi-transparent);
    font-family: var(--font-family-main) !important;
    letter-spacing: var(--letter-spacing);
}

.p-accordion .p-accordion-header .p-accordion-header-link { 
    padding: 1rem;
    border: 0px solid transparent !important;
    color: var(--text-color-dim);
    background: transparent !important;
    font-weight: 600;
    border-radius: 3px;
    transition: box-shadow 0.2s;
    font-family: var(--font-family-main) !important;
    letter-spacing: var(--letter-spacing);
}
.p-accordion .p-accordion-content {
    padding: 1rem;
    border: 0px solid transparent !important;
    color: var(--text-color-dim);
    background: transparent !important;
    font-weight: 400;
    border-radius: 3px;
    transition: box-shadow 0.2s;
    font-family: var(--font-family-main) !important;
    letter-spacing: var(--letter-spacing);
}

.custom-carousel .p-carousel-item .p-carousel-header .p-carousel-container .p-carousel-content {
    background-color: var(--bg-color-darker);
    border-color: var(--bg-color-darker);
    color: var(--text-color-dim);
    font-family: var(--font-family-main) !important;
    letter-spacing: var(--letter-spacing);
}

.card {
  font-family: var(--font-family-main) !important;
}

.p-fileupload {
  font-family: var(--font-family-main) !important;
}

.p-fileupload-content  {
 height: 130px;
}

.label {
  font-family: var(--font-family-main) !important;
}

.p-column-title {
  font-family: var(--font-family-main) !important;
}

table td span {
  font-family: var(--font-family-main) !important;
}
