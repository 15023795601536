/* File: src/components/test/TestManagementDashboard.css */

.test-management-dashboard {
    padding: 0rem;
  }
  
  .table-header {
    padding-bottom: 1rem;
  }
  
  .error {
    color: var(--red-500);
    font-weight: bold;
  }
  