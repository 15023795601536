/* File: src/components/test/TestResultDialog.css */

.conversation-container {
  padding: 0rem;
}

.message {
  display: flex;
  align-items: flex-start;
  margin-top: 0.5rem;    /* Add this line */
  margin-bottom: 0.5rem; /* Add this line */
}

.message-group {
  margin-bottom: 1.5rem;
}

.sender-label {
  display: block;
  font-size: 0.85rem;
  color: #888;
  margin-bottom: 0.5rem;
}

.agent-label {
  text-align: left;
  font-family: var(--font-family-main);
}

.human-label {
  text-align: right;
  font-family: var(--font-family-main);
}

.message.agent {
  justify-content: flex-start;
  font-family: var(--font-family-main);
}

.message.human {
  justify-content: flex-end;
}

.message-bubble {
  max-width: 60%;
  padding: 0.75rem 1rem;
  border-radius: 1rem;
  background-color: var(--agent-bg-color);
  color: var(--agent-text-color);
  word-wrap: break-word;
}

.message.agent .message-bubble {
  background-color: #e0e0e0; /* Light gray for agent messages */
  color: #000;
  border-top-left-radius: 0;
}

.message.human .message-bubble {
  background-color: #007bff; /* Blue for human messages */
  color: #fff;
  border-top-right-radius: 0;
}
