.chat-container {
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  justify-content: flex-end;
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  .chat-container {
    flex-direction: column;
    right: 10px;
    bottom: 10px;
  }
}

.chat-control {
  height: 75vh;
  width: 35vw;
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  .chat-control {
    width: 100%;
    height: 100%;
  }
}

.chat-popup {
  border-radius: 12px;
  background-color: #fff;
  width: 35vw;
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  .chat-popup {
    width: 100%;
  }
}

.chat-popup .ui-chat__messageheader,
.chat-popup span {
  display: none;
}

.chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #1390fb;
  border-radius: 10px 10px 0 0;
}

[data-icon-name="MessageSeen"] {
  color: #1390fb;
}

[data-ui-id="chat-composite-message"].eq {
  background-color: #fff;
  border: solid 2px #e5f3ed;
}

.chat-popup .jm {
  background-color: #e5f3ed;
}

.chat-header button {
  background: transparent;
  border: none;
  background-image: url("../assets/images/icon_dismiss.svg");
  background-repeat: no-repeat;
  width: 18px;
  display: block;
  text-indent: -9999px;
}

.chat-messages {
  max-height: 600px;
  overflow-y: auto;
  padding: 10px;
}

.chat-messages > div {
  margin: 5px 0;
  font-size: 0.8em;
  display: block;
}

.chat-messages > div > p {
  background-color: #e5f3ed;
  padding: 10px;
  border-radius: 5px;
  display: inline-block;
}

.chat-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.open-chat-button {
  padding: 12px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  background-color: #000000; /* #1390fb;*/
  color: lightgray;
  align-items: right;
  font-weight: bold;
  background-image: url("../assets/images/icon_question_circle.svg");
  background-position: 10px center;
  background-repeat: no-repeat;
  padding-left: 40px;
}

.open-chat-button:hover {
  background-color: #1177cc;
}

.You {
  text-align: right;
}

.chat-title {
  color: white;
  margin: 0;
}

.chat-control {
  height: 80vh;
}

[data-icon-name="PeopleAdd"] {
  display: none;
}
